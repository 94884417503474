// --------------Media queries breakpoints------------
/* smallest break point is $screen-md */

// Extra small screen / phone
$screen-xs: 480px;
$screen-xs-min: $screen-xs;

// Small screen / mixins.tablet
$screen-sm: 576px;
$screen-sm-min: $screen-sm;

// Medium screen / desktop
$screen-md: 768px;
$screen-md-min: $screen-md;

// Large screen / wide desktop
$screen-lg: 992px;
$screen-lg-min: $screen-lg;

// Extra large screen / full hd
$screen-xl: 1200px;
$screen-xl-min: $screen-xl;

// Extra extra large screen / large desktop
$screen-xxl: 1600px;
$screen-xxl-min: $screen-xxl;

// vertical paddings
$padding-lg: 24px; // containers
$padding-md: 16px; // small containers and buttons
$padding-sm: 12px; // Form controls and items
$padding-xs: 8px; // small items
$padding-xss: 4px; // more small

// vertical margins
$margin-lg: 24px; // containers
$margin-md: 16px; // small containers and buttons
$margin-sm: 12px; // Form controls and items
$margin-xs: 8px; // small items
$margin-xss: 4px; // more small


$content-min-xsmall: 320px;
$content-max-width: 1240px;

$padding-2: 2px;
$padding-4: $padding-xss;
$padding-8: $padding-xs;
$padding-12: $padding-sm;
$padding-16: $padding-md;
$padding-20: 20px;
$padding-24: $padding-lg;
$padding-28: 28px;
$padding-32: 32px;
$padding-36: 36px;
$padding-40: 40px;
$padding-48: 48px;
$padding-52: 52px;
$padding-60: 60px;
$padding-64: 64px;
$padding-80: 80px;

$margin-4: 4px;
$margin-8: 8px;
$margin-12: $margin-sm;
$margin-16: $margin-md;
$margin-20: 20px;
$margin-24: $margin-lg;
$margin-28: 28px;
$margin-32: 32px;
$margin-36: 36px;
$margin-40: 40px;
$margin-44: 44px;
$margin-48: 48px;
$margin-56: 56px;
$margin-64: 64px;

// subscription banner height
$subscription-banner-height-desktop: 50px;
$subscription-banner-height-mobile: 117px;

// header height
$header-height-mobile: 58px;
$header-height-desktop: 73px;

// menu width
$sider-width: 270px;

// footer height
$footer-height: 40px;

// tabs height
$tabs-top-mobile: 146px;
$tabs-top-desktop: 163px;

// header title height
$header-title-height-mobile: 73px;
$header-title-height-desktop: 73px;

// bottom height
$bottom-height-mobile: 70px;

// animations
$animate-5s-ease: 0.5s ease-in-out;
$animate-2s-ease: 0.2s ease-in-out;
