@use 'variables';
@use 'colors';

@mixin heading($font-size, $line-height) {
  font-family: var(--cera-pro), serif;
  font-style: normal;
  font-weight: bold;
  font-size: $font-size;
  line-height: $line-height;
}

@mixin body-text($font-size, $line-height) {
  font-family: var(--source-sans-pro), serif;
  font-style: normal;
  font-weight: normal;
  font-size: $font-size;
  line-height: $line-height;
}

@mixin info($fill-color, $background-color) {
  $icon-size: 14px;

  display: flex;
  align-items: center;
  padding: variables.$padding-4 variables.$padding-8;
  border-radius: 4px;
  width: fit-content;
  background-color: $background-color;

  .icon {
    padding-right: variables.$padding-4;

    path {
      fill: $fill-color;
    }

    width: $icon-size;
    height: $icon-size;
    min-width: $icon-size;
    min-height: $icon-size;
  }

  .text {
    white-space: nowrap;
    color: $fill-color;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@mixin mobile {
  @media (min-width: #{variables.$screen-xs}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{variables.$screen-sm}) {
    @content;
  }
}

@mixin desktop-medium {
  @media (min-width: #{variables.$screen-md}) {
    @content;
  }
}

@mixin desktop-large {
  @media (min-width: #{variables.$screen-lg}) {
    @content;
  }
}

@mixin desktop-xlarge {
  @media (min-width: #{variables.$screen-xl}) {
    @content;
  }
}

@mixin menuTitle($color) {
  padding: variables.$padding-12 variables.$padding-36 variables.$padding-12 variables.$padding-16 !important;
  height: auto;
  display: flex;
  color: $color !important;
  margin-bottom: 0;
  align-items: flex-end;
  & > span {
    line-height: 14px;
  }
  .ant-menu-submenu-arrow {
    color: $color !important;
  }
  svg {
    width: 16px !important;
    height: 16px !important;
    margin-right: variables.$margin-8 !important;

    & path {
      fill: $color !important;
    }
  }
  @include heading(14px, 18px);
}

@mixin bottomBarMenuTitle($color) {
  padding: variables.$padding-12 variables.$padding-16 !important;
  height: auto;
  display: flex;
  align-items: center;
  color: $color !important;
  margin-bottom: 0;

  .ant-menu-submenu-arrow {
    color: $color !important;
  }

  svg {
    height: 16px !important;
    width: 16px !important;
    margin-right: variables.$margin-8 !important;

    & path {
      fill: $color !important;
    }
  }

  @include heading(14px, 18px);
}
