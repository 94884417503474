@use '../../../styles/variables';
@use '../../../styles/mixins';

.logosWrap {
  display: flex;
  gap: 8px;
  justify-content: center;
  flex-direction: column;
  margin-bottom: variables.$margin-16;

  @include mixins.tablet {
    gap: 28px;
    flex-direction: row;
    margin-bottom: variables.$margin-28;
  }
}
