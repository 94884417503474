@use '../../../styles/colors';
@use '../../../styles/variables';
@use '../../../styles/mixins';

.authCardWrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: variables.$padding-20;
  background: colors.$white;
  border-radius: 32px;
  box-shadow: 0 4px 32px rgba(77, 81, 146, 0.15);
  @include mixins.desktop-xlarge {
    padding: variables.$padding-32;
  }

  .authCardContent {
    width: 100%;
  }
}

.authTitleWrap {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  margin-bottom: variables.$margin-16;
}
