@use '../../styles/variables';
@use '../../styles/colors';
@use '../../styles/mixins';

.authTitle {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  text-align: center;
  color: colors.$primary-500-base;
  div:first-of-type {
    font-weight: 400;
    color: colors.$primary-300;
  }
}

.headerText {
  color: colors.$primary-200;
}

.passwordFormItem {
  margin-bottom: variables.$margin-28 !important;

  label[class*="ant-form-item-required"] {
    width: 100% !important;
  }

  label[class*="ant-form-item-required"]::after {
    display: none !important;
  }

  .passwordLabelWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-height: 22px;
    width: 100%;

    .passwordLabel::after {
      display: inline-block;
      color: #ff4d4f;
      margin-left: 4px;
      font-size: 14px;
      font-family: SimSun, sans-serif;
      line-height: 1;
      content: '*';
    }

    .forgotPasswordBtn {
      padding: 0 !important;
    }
  }
}

.disabledInput {
  opacity: 20%;
}

.submitButton {
  margin-top: variables.$margin-16;
  width: 100%;
}

.divider {
  margin: variables.$margin-16 0 !important;

  @include mixins.tablet {
    margin: variables.$margin-28 0 !important;
  }
}

.footer {
  text-align: center;
  display: flex;
  flex-direction: column;

  * {
    margin: 0;
  }
}
