@use '../../../styles/colors';
@use '../../../styles/variables';
@use '../../../styles/mixins';

.buttonWrap {
  border-radius: 8px;
  border: 2px solid colors.$primary-100;
  padding: variables.$padding-8 variables.$padding-16;
  display: flex;
  gap: 16px;
  flex-direction: row;
  align-items: center;

  &:hover {
    box-shadow: 0 0 16px -4px rgba(131, 137, 169, 0.50);
    background: colors.$grey-violet-100 !important;
  }

  &:disabled {
    box-shadow: none !important;
    background: transparent !important;
    cursor: not-allowed;
  }

  @include mixins.tablet {
    padding: variables.$padding-16 variables.$padding-32;
    flex-direction: column;
  }
}

.selectedWrap {
  border-color: colors.$secondary-500-base;
  position: relative;

  &:hover {
    box-shadow: none;
    background: none !important;
  }
}

.authLogo {
  height: 40px;
  width: 40px;

  @include mixins.tablet {
    height: 143px;
    width: 143px;
  }
}

.text {
  color: colors.$primary-200;
  font-size: 16px;

  &.selectedText {
    color: colors.$secondary-300;
  }

  @include mixins.tablet {
    font-size: 21px;
  }
}

.checkIcon {
  width: 32px;
  height: 32px;

  padding: variables.$padding-8;
  right: variables.$margin-16;
  background: colors.$secondary-500-base;
  border-radius: 100px;
  position: absolute;

  path {
    fill: colors.$white;
  }

  @include mixins.tablet {
    width: 52px;
    height: 52px;

    padding: variables.$padding-16;
    bottom: -(variables.$margin-12);
    right: -(variables.$margin-12);
  }
}
